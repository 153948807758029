import type { SocialApiAdapter } from '../social-api';
import achievementResponse from './data/achievements.json';
import featureFlagsResponse from './data/feature-flags.json';
import userResponse from './data/user.json';
import achievementWithoutUserResponse from './data/achievement-without-user.json';
import type { components } from '../types/social-api-types';
import type { User } from '../../types';

async function getUserAchievementBySlug() {
  return Promise.resolve(
    achievementResponse as components['schemas']['ProfileAchievementDetail']
  );
}

async function markAchievementAsShown() {
  return Promise.resolve();
}

async function getFeatureFlags() {
  return Promise.resolve(
    featureFlagsResponse as components['schemas']['FeatureFlagsResponse']
  );
}

async function getUser() {
  return Promise.resolve(userResponse as User);
}

function banUser() {
  return Promise.resolve(null);
}

function banUserPrivate() {
  return Promise.resolve({
    current_ban: null,
  } as components['schemas']['PrivateUserCurrentBan']);
}

function unbanUser() {
  return Promise.resolve(null);
}

function unbanUserPrivate() {
  return Promise.resolve({ current_ban: null });
}

function getAccessToken() {
  return Promise.resolve({
    access_token: 'token',
  });
}

function getAchievementBySlug() {
  return Promise.resolve(
    achievementWithoutUserResponse as components['schemas']['Achievements']
  );
}
function getAchievementsByGroup() {
  return Promise.resolve([
    achievementWithoutUserResponse,
    {
      ...achievementWithoutUserResponse,
      slug: 'achievement-2',
    },
  ] as components['schemas']['Achievements'][]);
}

function getUserAchievementsByGroup() {
  return Promise.resolve([
    achievementResponse,
    {
      ...achievementResponse,
      slug: 'achievement-2',
    },
  ] as components['schemas']['ProfileAchievementDetail'][]);
}

const mockSocialAdapter: SocialApiAdapter = {
  getUserAchievementBySlug,
  getUserAchievementsByGroup,
  markAchievementAsShown,
  getFeatureFlags,
  getUser,
  banUser,
  banUserPrivate,
  unbanUser,
  unbanUserPrivate,
  getAccessToken,
  getAchievementBySlug,
  getAchievementsByGroup,
};

export default mockSocialAdapter;
