import { present } from '../presents';
import { subscribeToProfileChange } from '../util/shared-core-v3';
import { socialApi } from '../adapter';
import type { AchievementPopupScreen } from '../types';
import type { AchievementPopupContainerProps } from '../presents/achievement-popup';
import { cleanHash } from '../util/clean-hash';

async function renderPopup(props: {
  slug: string;
  userId?: number;
  newAchievement?: boolean;
  screen?: AchievementPopupScreen;
  trackingPointOfContact?: string;
  trackingProvider?: string;
}) {
  const AchievementPopup = (await import('../presents/achievement-popup/index'))
    .default;

  return new Promise<void>((resolve) => {
    const unmount = present<AchievementPopupContainerProps>(AchievementPopup, {
      ...props,
      onClose() {
        cleanHash();
        unmount();
        resolve();
      },
    });
  });
}

function open(props: {
  slug: string;
  userId?: number;
  screen?: AchievementPopupScreen;
  trackingPointOfContact?: string;
  trackingProvider?: string;
}) {
  return renderPopup(props);
}

function subscribeToNewUserAchievement() {
  subscribeToProfileChange(async (profile) => {
    if (!profile?.last_obtained_achievement) {
      return;
    }

    const pathParts = window.location.pathname.split('/');
    const articleSlug = pathParts[pathParts.length - 1];

    try {
      renderPopup({
        slug: profile.last_obtained_achievement.slug,
        userId: profile.id,
        newAchievement: true,
        trackingPointOfContact: 'obtained_achievement',
        trackingProvider: articleSlug,
      });
      socialApi.markAchievementAsShown(profile.last_obtained_achievement.slug);
    } catch (e) {
      console.error(e);
    }
  });
}

function subscribeToHashChange() {
  const listener = (e: HashChangeEvent) => {
    const { hash } = new URL(e.newURL);
    const params = new URLSearchParams(hash.replace('#', '?'));
    const slug = params.get('achievement-popup');

    if (slug) {
      try {
        renderPopup({
          slug,
          trackingProvider: params.get('provider') ?? undefined,
          trackingPointOfContact: params.get('pointOfContact') ?? undefined,
        });
      } catch (e) {
        console.error(e);
      }
    }
  };
  window.addEventListener('hashchange', listener);
}

export const achievementPopup = {
  open,
  subscribeToNewUserAchievement,
  subscribeToHashChange,
};
