import React from 'react';
import ReactDOMClient from 'react-dom/client';

export function present<P extends {}>(
  Component: React.ComponentType<P>,
  props: P
) {
  const holder = createHolder();
  const root = ReactDOMClient.createRoot(holder);

  root.render(<Component {...props} />);

  function unmount() {
    root.unmount();
    holder.remove();
  }

  return unmount;
}

function createHolder() {
  const holder = document.createElement('div');
  document.body.appendChild(holder);
  return holder;
}
