import type { PopupsCore, PopupsCoreHolder } from '../types';

export function getPopupsCore(wnd: PopupsCoreHolder | null = getHolder()) {
  return wnd?.tjlib?.popups;
}

function getHolder() {
  return window as PopupsCoreHolder;
}

export function setPopupsCore(core: PopupsCore) {
  const wnd = getHolder();
  wnd.tjlib = wnd.tjlib || {};
  wnd.tjlib.popups = core;
}
