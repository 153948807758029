import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { setPopupsCore } from './util/window';
import type { PopupsCore } from './types';
import { achievementPopup } from './controllers/achievement-popup';
import { banPopup } from './controllers/ban-popup';

export default function init() {
  if (typeof window === 'undefined') {
    return undefined;
  }

  if (typeof React === 'undefined' || typeof ReactDOMClient === 'undefined') {
    throw new Error(
      'facade did not provide LIBS[react, react-dom] required for popups-core'
    );
  }

  initCore();
}

function initCore() {
  const core: PopupsCore = {
    achievement: {
      open: achievementPopup.open,
    },
    ban: {
      open: banPopup.openBan,
    },
    unban: {
      open: banPopup.openUnban,
    },
  };
  achievementPopup.subscribeToNewUserAchievement();
  achievementPopup.subscribeToHashChange();
  setPopupsCore(core);
}
