import { present } from '../presents';
import type { BanPopupProps } from '../presents/ban-popup';
import type { BanPopupResponse } from '../types';

async function renderPopup(props: Omit<BanPopupProps, 'onClose'>) {
  const BanPopup = (await import('../presents/ban-popup')).default;

  return new Promise((resolve) => {
    const unmount = present<BanPopupProps>(BanPopup, {
      ...props,
      onClose(res) {
        unmount();
        resolve(res);
      },
    });
  }) as Promise<BanPopupResponse>;
}

function openBan(
  props: Omit<BanPopupProps, 'onClose' | 'modalType' | 'currentBan'>
) {
  return renderPopup({ ...props, modalType: 'ban' });
}

function openUnban(
  props: Omit<BanPopupProps, 'onClose' | 'modalType' | 'commentId'>
) {
  return renderPopup({ ...props, modalType: 'unban' });
}

export const banPopup = {
  openBan,
  openUnban,
};
