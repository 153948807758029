import flowResponse from './data/flows-images.json';
import articleCardsResponse from './data/article-cards.json';
import articlesCardDiscussResponse from './data/articles-discuss-flow.json';
import type { MercuryAdapter } from '../mercury-api';
import type { components } from '../types/mercury-api-types';

async function getFlowsImagesBySlug(slugsString: string) {
  return Promise.resolve(flowResponse);
}

async function getArticleCardsBySlugs(slugs: string[]) {
  return Promise.resolve(
    articleCardsResponse as components['schemas']['FlowCard'][]
  );
}

async function getMostCommentedArticlesFromDiscussFlow() {
  return Promise.resolve(
    articlesCardDiscussResponse as components['schemas']['FlowCard'][]
  );
}

async function getMostCommentedArticles() {
  return Promise.resolve(
    articleCardsResponse as components['schemas']['FlowCard'][]
  );
}

const mockMercuryApiAdapter: MercuryAdapter = {
  getFlowsImagesBySlug,
  getArticleCardsBySlugs,
  getMostCommentedArticlesFromDiscussFlow,
  getMostCommentedArticles,
};

export default mockMercuryApiAdapter;
